// Third party
import React from "react";
import { graphql } from "gatsby";

// Components
import Layout from "../components/Layout";
import Section from "../components/Section";
import Container from "../components/Container";
import Hero from "../components/Hero";
import Breadcrumb from "../components/Breadcrumb";
import ContentList from "../components/ContentList";
import SEO from "../components/seo";

const breadcrumbs = [
  {
    id: 1,
    title: "Home",
    slug: "/",
  },
  {
    id: 2,
    title: "Docenten",
    slug: "/teachers/",
  },
];

const WorkshopsPage = ({ data }) => {
  const {
    allContentfulTeacher: { edges: items },
  } = data;
  return (
    <Layout>
      <SEO
        title="Hormoon Yoga docenten"
        description="Lees hier informatie over de Hormoon Yoga docenten"
        slug={"teachers"}
      />
      <Hero small>
        <Container centered>
          <h1>Docenten</h1>
          <p>Hormoon Yoga docenten</p>
        </Container>
      </Hero>
      <Breadcrumb items={breadcrumbs} />
      <Section>
        <Container>
          <ContentList items={items} parent="teachers" />
        </Container>
      </Section>
    </Layout>
  );
};

export default WorkshopsPage;

export const query = graphql`
  query TeacherQuery {
    allContentfulTeacher(filter: { node_locale: { eq: "nl" } }, limit: 1000) {
      edges {
        node {
          id
          title
          body {
            childMarkdownRemark {
              excerpt
              html
            }
          }
          slug
          avatar {
            fixed(width: 448, height: 448) {
              src
            }
          }
        }
      }
    }
  }
`;
